import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import parser from 'tld-extract';

let lang = 'lv';
const url = parser(window.location.href, { allowUnknownTLD: true });

if (url.domain === 'lays.ee') {
  lang = 'et';
} else if (url.domain === 'laysloterija.lv') {
  lang = 'lv';
} else if (url.domain === 'lays.lt') {
  lang = 'lt';
} else {
  if (url.sub === 'lays.ee' || url.sub === 'lays-ee') {
    lang = 'et';
  } else if (url.sub === 'laysloterija.lv' || url.sub === 'laysloterija-lv') {
    lang = 'lv';
  } else if (url.sub === 'lays.lt' || url.sub === 'lays-lt') {
    lang = 'lt';
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: 'et',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
