import React, { ReactElement } from 'react';
import './Rules.scss';

const Lv: React.FC = (): ReactElement => {
  return (
    <div className="Lv">
      <ol>
        <li>
          ”Lay’s Music 2022” ir Latvijā notiekoša patērētāju loterija, kuru rīko
          “Lay’s” zīmola preču izplatītājs Latvijā: SIA "MV Latvia", Medus iela
          7, Rīga, LV-1048, vienotais reģistrācijas Nr.40003787568, turpmāk
          tekstā - Loterijas Organizētājs.
        </li>
        <li>
          Loterijas norises teritorija – jebkura tirdzniecības vieta visā
          Latvijas Republikas teritorijā, kurā tiek izplatītas un ir iespējams
          iegādāties Loterijas preces.{' '}
        </li>
        <li>
          Loterijā Latvijas Republikas teritorijā var piedalīties ikviena
          persona, bez vecuma ierobežojuma, kura iegādājas Loterijas Preci.
        </li>
        <li>
          Loterijas norises kārtību saskaņā ar šiem noteikumiem nosaka Loterijas
          Organizētājs, saskaņojot tos ar Latvijas Republikas Izložu un
          azartspēļu uzraudzības inspekciju, un to izpilde un ievērošana ir
          obligāta visiem Loterijas dalībniekiem.
        </li>
        <li>
          Loterijas norises sākums ir 2022.gada 01.novembris un beigu datums ir
          2023.gada 02.janvāris.{' '}
          <ol>
            <li>
              Periods, kurā iespējams nopirkt Loterijas preces un pieteikt savu
              dalību Loterijā ir no 2022.gada 01.novembra plkst.00.00 līdz
              2022.gada 30.decembra plkst.23.59. Reģistrācija, kura tiek veikta
              pirms vai pēc iepriekš minētā perioda, tiek uzskatīta par nederīgu
              un nepiedalās balvu izlozēs.
            </li>
          </ol>
        </li>
        <li>
          Loterijas preces ir jebkura “Lay’s” zīmola prece - čipsi, kas
          nopērkami Latvijas Republikas teritorijā un ir marķēti ar akcijas
          lentu uz paciņas.
        </li>
        <li>
          Loterijas preču skaits, kuras iegādājoties var piedalīties Loterijā,
          ievērojot saprātīgu pieprasījumu, netiks ierobežots. Loterijas preces
          var būt pieejamas tirdzniecībā arī pirms vai pēc šo noteikumu
          5.1.punktā norādītā perioda, taču to iegāde pirms vai pēc šajos
          noteikumos norādītā termiņa nedod tiesības piedalīties Loterijā.
        </li>
        <li>
          Loterijas norises kārtība - lai piedalītos Loterijā patērētājam
          jāiegādājas šo noteikumu 6.punktā norādītā “Lay’s” zīmola prece.
          Patērētājam jāsaglabā pirkuma čeks un jāreģistrē čeka numurs interneta
          vietnē:{' '}
          <a
            href="https://www.laysloterija.lv"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            www.laysloterija.lv
          </a>{' '}
          .{' '}
          <ol>
            <li>
              Reģistrējot čeka numuru interneta vietnē{' '}
              <a
                href="https://www.laysloterija.lv"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                www.laysloterija.lv{' '}
              </a>{' '}
              anketā jānorāda: vārds, uzvārds, čeka numurs, dalībnieka tālruņa
              numurs un e-pasta adrese. Ja reģistrēšanās interneta vietnē
              <a
                href="https://www.laysloterija.lv"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                www.laysloterija.lv{' '}
              </a>{' '}
              notiek veiksmīgi, ierīces monitorā tiek parādīts apstiprinošs
              paziņojums. Neveiksmīgas reģistrācijas gadījumā Loterijas
              dalībnieks par to tiek informēts, dodot iespēju labot datus līdz
              brīdim, kad reģistrācija ir veiksmīga, pretējā gadījumā Loterijas
              dalībnieks netiks reģistrēts dalībai Loterijā.
            </li>
            <li>
              Reģistrējoties šo noteikumu 8.punktā un tā apakšpunktos norādītajā
              veidā, Loterijas dalībnieks uzņemas pilnu atbildību par patiesas
              informācijas norādīšanu, par tās sastādīšanas precizitāti un
              kļūdām, norādot anketā pieprasīto informāciju.
            </li>
            <li>
              Loterijas dalībniekam jāsaglabā Loterijas preces pirkuma čeks, jo
              tas kalpo par Loterijas preces iegādes apliecinājumu un
              reģistrācijas identifikāciju, kā arī ir nosacījums balvas
              saņemšanai.
            </li>
          </ol>
        </li>
        <li>
          Viens Loterijas dalībnieks var reģistrēties Loterijai vairākkārt tikai
          pēc atkārtota Loterijas preču pirkuma, reģistrējot jaunu pirkuma čeka
          numuru.{' '}
          <ol>
            <li>
              Ja persona mēģina reģistrēt vienu čeku vairākkārt, tādā veidā
              mēģinot palielināt savas izredzes laimēt, Loterijas Organizētājs
              patur tiesības, atsevišķi neinformējot šo Loterijas dalībnieku par
              čeka numura neunikalitāti, anulēt šīs reģistrācijas, tādā veidā
              nodrošinot izlozi, ievērojot vienlīdzības un godīgas dalības
              principus.{' '}
            </li>
          </ol>
        </li>
        <li>
          Kad dalībnieks piereģistrē savu iegūto čeka numuru, tas piedalās
          nākošajā izlozē pēc tā reģistrācijas, kas notiek tuvākajā datumā, kas
          minēts šo noteikumu 12.punktā. Galvenās balvas izlozē piedalās visas
          reģistrācijas, kas saņemtas šo noteikumu 5.1.punktā norādītā termiņā.
        </li>
        <li>
          Laimestu fonds: 8 “Sony” SRS-XP500 bezvadu skaļruņi ar kopējo vērtību
          2152.00 euro, 40 “Lay’s” zīmola čipsu kastes ar kopējo vērtību 853.78
          euro, un galvenā balva - mūzikas grupas “Gacho” privāts koncerts ar
          kopējo vērtību 5000.00 euro.
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Laimesta nosaukums</strong>
                </td>
                <td>
                  <strong>Viena laimesta vērtība ieskaitot PVN (euro)</strong>
                </td>
                <td>
                  <strong>Skaits</strong>
                </td>
                <td>
                  <strong>Kopējā summa ieskaitot PVN (euro)</strong>
                </td>
              </tr>
              <tr>
                <td>Mūzikas grupas “Gacho” privāts koncerts</td>
                <td>5000.00</td>
                <td>1</td>
                <td>5000.00</td>
              </tr>
              <tr>
                <td>“Sony” SRS-XP500 bezvadu skaļrunis</td>
                <td>269.00</td>
                <td>8</td>
                <td>2152.00</td>
              </tr>
              <tr>
                <td>“Lay’s” zīmola čipsu kaste (Fromage, 140 gr.)</td>
                <td>21.34</td>
                <td>40</td>
                <td>853.78</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <strong>Kopā</strong>
                </td>
                <td>
                  <strong>8005.78</strong>
                </td>
              </tr>
            </tbody>
          </table>
          Kopā 49 (četrdesmit deviņas) balvas ar kopējo vērtību 8005.78 EUR
          (astoņi tūkstoši pieci euro un septiņdesmit astoņi euro centi)
          ieskaitot PVN.
        </li>
        <li>
          Tiek noteiktas astoņas izlozes, kurās piedalās saskaņā ar šo noteikumu
          5.1.punktu, kā arī 8.punktu un tā apakšpunktiem saņemtās čeka numuru
          reģistrācijas, un tieši:{' '}
          <ul>
            <li>
              1.izloze notiek 2022.gada 08.novembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 01.novembra plkst. 00:00 līdz
              2022.gada 07.novembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes;
            </li>
            <li>
              2.izloze notiek 2022.gada 15.novembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 08.novembra plkst. 00:00 līdz
              2022.gada 14.novembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes;
            </li>
            <li>
              3.izloze notiek 2022.gada 22.novembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 15.novembra plkst. 00:00 līdz
              2022.gada 21.novembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes;
            </li>
            <li>
              4.izloze notiek 2022.gada 29.novembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 22.novembra plkst. 00:00 līdz
              2022.gada 28.novembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes;
            </li>
            <li>
              5.izloze notiek 2022.gada 06.decembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 29.novembra plkst. 00:00 līdz
              2022.gada 05.decembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes;
            </li>
            <li>
              6.izloze notiek 2022.gada 13.decembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 06.decembra plkst. 00:00 līdz
              2022.gada 12.decembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes;
            </li>
            <li>
              7.izloze notiek 2022.gada 20.decembrī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 13.decembra plkst. 00:00 līdz
              2022.gada 19.decembra plkst. 23:59, izlozēwww.laysloterija.lvjot
              vienu “Sony” SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu
              kastes;
            </li>
            <li>
              8.izloze notiek 2023.gada 02.janvārī, kurā piedalās čeka numuru
              reģistrācijas, kas saņemtas saskaņā ar šo noteikumu 8.punktu un tā
              apakšpunktiem, no 2022.gada 20.decembra plkst. 00:00 līdz
              2022.gada 30.decembra plkst. 23:59, izlozējot vienu “Sony”
              SRS-XP500 skaļruni un piecas “Lay’s” zīmola čipsu kastes, un
              galvenās balvas - privāta mūzikas grupas “Gacho” koncerta izloze,
              kurā piedalās visas čeka numuru reģistrācijas, kas saņemtas
              saskaņā ar šo noteikumu 8.punktu un tā apakšpunktiem, no 2022.gada
              01.novembra plkst.00.00 līdz 2022.gada 30.decembra plkst.23.59.
            </li>
          </ul>
          <ol>
            <li>
              Visas izlozes tiek rīkotas Loterijas Organizētāja pilnvarotās
              personas telpās – SIA “Sorbum LV” birojā, pēc adreses: Jūrkalnes
              iela 15/25, Rīga, katras attiecīgās dienas plkst. 14:00.
            </li>
            <li>
              Gadījumā, ja izlozētie laimētāji saskaņā ar šiem noteikumiem nav
              tiesīgi saņemt balvu (t.sk. laimētājs nespēj pierādīt savu dalību
              Loterijā vai čeka numura reģistrācija loterijai nav veikta
              atbilstoši šiem noteikumiem), tad izlozētā balva paliek Loterijas
              Organizētāja īpašumā un rīcībā, un Loterijas Organizētājs ir
              tiesīgs rīkoties ar to pēc saviem ieskaitiem, tai skaitā nodot to
              cita Loterijas Dalībnieka īpašumā pēc savas izvēles.
            </li>
            <li>
              {' '}
              Katrā izlozē katrai balvai tiek noteikti rezerves laimētāji, kuru
              vārdi netiek publicēti, ar kuriem Loterijas Organizētāja
              pilnvarotā persona – SIA “Sorbum LV” nepieciešamības gadījumā
              sazinās pēc Loterijas norises beigu datuma, ja balvu ieguvēji nav
              ieradušies pakaļ savām balvām, un kuriem Loterijas Organizētājs
              šādā gadījumā, saskaņā ar noteikumu 12.2.punkta noteikumiem, ir
              tiesīgs nodot attiecīgo Loterijas balvu.
            </li>
          </ol>
        </li>
        <li>
          Kopējais Loterijas dalībnieku skaits nav iepriekš zināms, bet tiek
          prognozēts, ka izlozēm savu dalību pieteiks vienāds dalībnieku skaits
          reģistrējot unikālos čeka numurus. Aptuvenās izredzes laimēt ir 49
          pret 7000.
        </li>
        <li>
          Loterijas dalībnieku un unikālo kodu reģistrāciju, kā arī izlozes
          norisi un tās veikšanu nodrošina Loterijas Organizētāja pilnvarotā
          persona – SIA “Sorbum LV”, vienotais reģistrācijas Nr.40103605552, ar
          saviem vai apakšuzņēmēju spēkiem un resursiem.{' '}
        </li>
        <li>
          Informācija un personas dati, ko Loterijas dalībnieki iesniedz
          reģistrējoties dalībai Loterijā, tiek apkopoti, apstrādāti un
          uzglabāti saskaņā ar Latvijas Republikā spēkā esošo likumdošanas un
          normatīvo aktu prasībām attiecībā uz personas datu aizsardzību.
        </li>
        <li>
          Reģistrējoties Loterijai, Loterijas dalībnieki apliecina, ka piekrīt
          šiem noteikumiem un, ka Loterijas Organizētājs un tā pilnvarotās
          personas drīkst apstrādāt, uzglabāt un izmantot viņu sniegtos personas
          datus balvu ieguvēju noteikšanai, to identifikācijai, publicēšanai
          interneta vietnē{' '}
          <a
            href="https://www.laysloterija.lv"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            www.laysloterija.lv
          </a>{' '}
          , un laimestu izsniegšanai. Datu apstrādes mērķis ir nodrošināt balvu
          ieguvēju identifikāciju un balvu izsniegšanu.{' '}
          <ol>
            <li>
              Iesniedzot vai reģistrējot savus personas datus dalībai Loterijā,
              Loterijas dalībnieki piekrīt, ka dati tiek apstrādāti un uzglabāti
              visu Loterijas laiku, kā arī pēc Loterijas noslēguma tiek
              saglabāti Loterijas Organizētāja datu bāzēs tik ilgi, cik tas
              nepieciešams saskaņā ar piemērojamiem normatīviem aktiem.
            </li>
          </ol>
        </li>
        <li>
          Katras izlozes balvu ieguvēji tiek publicēti interneta vietnē
          <a
            href="https://www.laysloterija.lv"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            www.laysloterija.lv
          </a>{' '}
          , līdz nākamās darba dienas, pēc izlozes dienas, beigām, bet 2023.gada
          02.janvāra izlozes balvu ieguvēji tiks publicēti līdz tās pašas dienas
          beigām.
        </li>
        <li>
          Visi balvu ieguvēji jeb pilns uzvarētāju saraksts tiek publicēts
          interneta vietnē{' '}
          <a
            href="https://www.laysloterija.lv"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            www.laysloterija.lv
          </a>{' '}
          , līdz 2023.gada 02.janvāra dienas beigām.
        </li>
        <li>
          Balvas tiek uzglabātas pie Loterijas Organizētāja pilnvarotās personas
          – SIA “Sorbum LV”, birojā pēc adreses: Jūrkalnes iela 15/25, Rīga, un
          tās tiek izsniegtas balvu ieguvējiem personīgi, saskaņā ar šo
          noteikumu prasībām.
        </li>
        <li>
          Lai vienotos par laimēto balvu izsniegšanu, SIA “Sorbum LV” izlozes
          dienā pēc izlozes sazinās ar balvu ieguvējiem, rakstot uz anketā
          norādīto e-pasta adresi, nosūtot īsziņu vai piezvanot uz norādīto
          tālruņa numuru.
        </li>
        <li>
          Lai saņemtu balvu, balvas ieguvējam jāuzrāda personu apliecinošs
          dokuments (pase vai personas apliecība (eID)) un jāuzrāda laimējušais
          čeks Loterijas Organizētāja pilvarotajai personai – SIA “Sorbum LV” un
          jāļauj nokopēt laimējušo čeku un saglabāt tā kopiju.{' '}
          <ol>
            <li>
              Ja balvas ieguvējs nevar uzrādīt kādu no šajos noteikumos
              norādītiem personu apliecinošiem dokumentiem un/vai laimējušā čeka
              oriģinālu vai dokumentos esošā informācija nesakrīt ar
              reģistrācijā Loterijai norādīto informāciju, Loterijas
              Organizētājs vai tā pilnvarotā persona ir tiesīgs balvu
              neizsniegt.
            </li>
            <li>
              Ja Loterijā reģistrētais un izlozē laimējušais čeka numurs nav
              identisks uzrādītā čekā norādītajam čeka numuram balvu saņemot,
              t.i., simbolu kombinācija un secība precīzi neatbilst, čeka numurs
              nesatur tieši tos pašus simbolus, tam pievienoti papildu simboli
              vai kādi no simboliem aizvietoti ar līdzīgiem, vai Loterijas
              Organizētājam rodas aizdomas par krāpšanas mēģinājumu, Loterijas
              Organizētājs ir tiesīgs balvas ieguvējam balvu neizsniegt.
            </li>
          </ol>
        </li>
        <li>
          Ja balvas ieguvējs ir nepilngadīga persona, balvu var saņemt viņa
          likumiskais pārstāvis, uzrādot balvas ieguvēja un savu personu
          apliecinošu dokumentu (pasi vai personas apliecību (eID)) un čeka
          oriģinālu.
        </li>
        <li>
          Loterijas Organizētājs nodrošina, ka laimētās balvas to ieguvēji varēs
          personiski saņemt līdz 2023.gada 17.janvārim šo noteikumu 19.punktā
          norādītā adresē. Gadījumā, ja valstī būs noteikta Ārkārtas situācija,
          balvas saņemšana būs iespējama izmantojot pasta pakalpojumus, kurus
          segs Loterijas Organizētājs.{' '}
          <ol>
            <li>
              Ja balvu ieguvēji šo noteikumu 23.punktā norādītajā termiņā nav
              ieradušies pēc balvām, balvu ieguvēji zaudē savu balvu ieguvēja
              statusu, un balvas bez jebkādas kompensācijas pāriet Loterijas
              Organizētāja īpašumā, tai skaitā balvu ieguvējiem šādā gadījumā
              nav tiesības pretendēt uz balvas vērtības atlīdzināšanu naudā.
            </li>
            <li>
              Galvenās balvas ieguvējam lēmums par koncerta datumu jāpieņem līdz
              2023.gada 02.februārim. Galvenās balvas saņemšanas termiņš ir 1
              gads - no 2023.gada 03.janvāra līdz 2024.gada 02.janvārim.{' '}
            </li>
            <li>
              Galvenā balvā ir iekļauts:{' '}
              <ol>
                <li>
                  apmaksāts mūzikas grupas vienas stundas privāts koncerts,
                  ievērojot valstī noteiktās Ārkārtas situācijas un/vai vīrusa
                  “Covid-19” pandēmijas izplatības dēļ noteiktos ierobežojumus;
                </li>
                www.laysloterija.lv
                <li>
                  tehniskais aprīkojums koncerta norisei un ierakstīšanai;
                </li>
                <li>
                  mūzikas grupas transporta izdevumi un/vai koncerta norises
                  vieta attālināta koncerta gadījumā, un ar to saistītie
                  izdevumi.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              23.4. Galvenās balvas laimētājam tiek nodrošināta iespēja saņemt
              galveno balvu attālināti tiešsaistes režīmā, bez papildus maksas
              tiešsaistes režīmā pieslēdzoties saskaņotajam koncerta linkam
              (tiešsaistes platforma vai web adrese) un baudīt koncertu on-line
              (tiešsaistes) režīmā. Galvenās balvas laimētājs šajā punktā
              norādītā gadījumā ir atbildīgs par interneta pieslēgumu un datu
              pārraidi (bezmaksas Wi-Fi, maksas pieslēgums, u.t.t.), un to
              tehniskajiem parametriem vietā, kur viņš ir izvēlējies baudīt
              koncertu, kā arī ir atbildīgs par savu tehnisko iekārtu (telefons,
              planšete, televizors) ar kuras palīdzību koncerts tiek saņemts
              tiešsaistes režīmā, tai skaitā tās piemērotību un darbību koncerta
              laikā. Visas nianses un pamatotās prasības attiecībā uz koncertu
              un tā tehnisko nodrošinājumu, galvenās balvas laimētājs saskaņo ar
              mūzikas grupu un Loterijas Organizētāju.
            </li>
            <li>
              Galveno balvu - saskaņoto privāto koncertu var atlikt tikai
              pārceļot tā norises laiku uz ar galvenās balvas saņēmēju saskaņotu
              laiku, ja pastāv šādi apstākļi:{' '}
              <ul>
                <li>
                  mūzikas grupas dalībnieki saslimst un nevar uzstāties iepriekš
                  saskaņotā laikā;
                </li>
                <li>
                  Latvijas Republikā ir spēkā esošas vai stājas spēkā
                  likumdošanas normas, kas nosaka ierobežojumus vīrusa
                  “Covid-19” izplatības ierobežošanai, vai ir izsludināta
                  Ārkārtas situācija, kā rezultātā tiek aizliegta koncertu
                  norise klātienē vai tiešsaistes režīmā;
                </li>
                <li>force majeure gadījumā.</li>
              </ul>
            </li>
            <li>
              Vīrusa “Covid-19” pandēmijas izplatības dēļ noteiktie
              ierobežojumi. Tā kā vīrusa “Covid-19” izplatības draudi joprojām
              saglabājas, Loterijas Organizators nodrošina iespēju saņemt
              galveno balvu attālināti, tiešsaistes režīmā pušu saskaņotā
              formātā (ZOOM, Team, Skype, Messenger vai citā platformā). Jebkurā
              gadījumā, koncerta sniegšanas laikā gan skatuves mākslinieks, gan
              galvenās balvas ieguvējs, katrs savā vietā, kur tas atradīsies, ir
              atbildīgi un apņemas ievērot visas spēkā esošās likumdošanas
              normas un noteiktos ierobežojumus vīrusa “Covid-19” izplatības
              ierobežošanai.
            </li>
            <li>
              Galvenā balva ir privāts koncentrs, un Galvenās balvas laimētājs
              nav tiesīgs saņemt ienākumus no Galvenās balvas, tai skaitā, bet
              ne tikai, publiskot koncerta norisi (ar vai bez atlīdzības),
              pārdot biļetes uz koncertu trešām personāwww.laysloterija.lvm,
              izmantot Galveno balvu reklāmas aktivitātēs, u.c..
            </li>
          </ol>
        </li>
        <li>
          Saņemot balvas, balvu ieguvēji un Loterijas Organizētāja pilnvarotā
          persona - SIA “Sorbum LV”, paraksta balvas pieņemšanas-nodošanas aktu.
          Pēc balvas saņemšanas un pieņemšanas-nodošanas akta parakstīšanas,
          balvu ieguvēji nevar vērsties pie Loterijas Organizētāja ar pretenziju
          par balvu.
        </li>
        <li>
          Loterijas Organizētājs neatbild par balvu ieguvējam iespējami radītiem
          zaudējumiem, kas radušies, lietojot balvu.
        </li>
        <li>
          Balvas apmaiņa pret citu balvu vai tās vērtības samaksa skaidrā naudā
          nav iespējama.
        </li>
        <li>
          Ar dalību izlozē vai balvas saņemšanu saistītie papildu izdevumi, kas
          nav norādīti šajos noteikumos, piemēram, transporta izdevumi, datu
          pārraides izmaksas vai telefona sarunu izmaksas, elektroenerģijas
          patēriņš Galvenās balvas ieguvēja izvēlētā koncerta vietā, balvu
          ieguvējiem un loterijas dalībniekiem netiek atlīdzināti.
        </li>
        <li>
          Loterijas Organizētājs nav atbildīgs, ja Loterijas dalībnieki neievēro
          šos noteikumus.
        </li>
        <li>
          Loterijas Organizētājs neuzņemas atbildību par Loterijas dalībnieku
          izslēgšanu no izlozes, kā arī par balvu neizsniegšanu gadījumos, kad
          nav ievēroti šie noteikumi vai Loterijas dalībnieku sniegtā
          informācija ir nepatiesa vai neprecīza, vai, ja ar balvu ieguvējiem
          nav izdevies sazināties no Loterijas Organizētāja neatkarīgu iemeslu
          dēļ.
        </li>
        <li>
          Visas pretenzijas par Loterijas rīkošanu un/vai norisi ir jānosūta
          rakstiski Loterijas Organizētājam – SIA “MV Latvia”, pēc adreses:
          Medus iela 7, Rīga, Latvija, LV-1048, vai elektroniski uz
          birojs@mvgroup.eu (elektroniski parakstītas ar drošu elektonisko
          parakstu un satur laika zīmogu) līdz 2023.gada 17.janvārim, ar norādi
          “Lay’s Music 2022” loterija, pilnībā izklāstot iebildumus un to
          pamatojumu, kā arī pievienojot dokumentus vai to kopijas, uz kurām ir
          atsauces pretenzijā.{' '}
          <ol>
            <li>
              Pretenzijas, kas iesniegtas pēc 2023.gada 17.janvāra, netiks
              izskatītas un neradīs pusēm jebkādas juridiskas sekas.
            </li>
            <li>
              Loterijas Organizētājs izskata saņemto pretenziju un atbild uz to
              15 (piecpadsmit) darba dienu laikā pēc tās saņemšanas. Ja
              Loterijas Organizētājs uzskata pretenziju par pamatotu, tas
              atbildē norāda pretenzijas apmierināšanas kārtību un termiņu. Ja
              pretenzijas iesniedzēju neapmierina Loterijas Organizētāja sniegtā
              atbilde, persona var aizsargāt savas tiesības Latvijas Republikas
              likumdošanas normās noteiktā kārtībā.
            </li>
            <li>
              Datums pasta zīmogā uz aploksnes nosūtītajām pretenzijām ir
              uzskatāms par pierādījumu pretenzijas iesniegšanas termiņa
              noteikšanai, bet elektroniski nosūtītajām pretenzijām termiņš tiek
              noteikts pēc pretenzijas saņemšanas datuma Loterijas Organizētāja
              norādītajā e-pasta adresē.{' '}
            </li>
            <li>
              Visās pretenzijās to iesniedzējam ir jānorāda Loterijas dalībnieka
              vārds, uzvārds un precīza adrese.
            </li>
          </ol>
        </li>
        <li>
          Loterijas Organizētāja, tā pilnvaroto personu un Loterijas dalībnieku
          tiesības un pienākumi ir noteikti tikai šajos noteikumos.{' '}
          <ol>
            <li>
              Loterijas Organizētājs paziņo un brīdina, ka reklāmas materiālos
              pieejamajai informācijai par Loteriju ir tikai informatīvs
              raksturs.
            </li>
          </ol>
        </li>
        <li>
          Izložu un azartspēļu uzraudzības inspekcijas apstiprinātu šo noteikumu
          redakciju ir iespējams iepazīties interneta vietnē{' '}
          <a
            href="https://www.laysloterija.lv"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            www.laysloterija.lv
          </a>{' '}
          .
        </li>
      </ol>
    </div>
  );
};

export default Lv;
